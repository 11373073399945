var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "lipdub-mission", attrs: { column: "", "fill-height": "" } },
    [
      !_vm.isAutopilotOn
        ? [
            _vm.isHost && !!_vm.audioUrl && !_vm.recording
              ? [
                  _vm.paused
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "play-stop-btn",
                          attrs: { icon: "", color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.play()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("play_arrow")])],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "play-stop-btn",
                          attrs: { color: "success", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.stop()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("stop")])],
                        1
                      ),
                ]
              : _vm._e(),
            _vm.isSuper && !!_vm.audioUrl
              ? [
                  !_vm.recording && _vm.isEncoded
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "replay-stop-btn",
                          attrs: { icon: "", color: "purple" },
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("replay")])],
                        1
                      )
                    : _vm._e(),
                  !_vm.recording
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "record-stop-btn",
                          attrs: { icon: "", color: "red" },
                          on: {
                            click: function ($event) {
                              return _vm.record()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("play_arrow")])],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "record-stop-btn",
                          attrs: { color: "red", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.stopRecording()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("stop")])],
                        1
                      ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.showLyrics
        ? _c(
            "v-flex",
            {
              ref: "root",
              staticClass: "mission-instructions",
              attrs: { "d-flex": "" },
            },
            [
              _c(
                "transition",
                { attrs: { name: "mission-instructions-transition" } },
                [
                  _c("ResizableText", {
                    key: "lipdub-instructions-chunk-" + _vm.index,
                    class: "mission-instructions-chunk chunk-" + _vm.index,
                    attrs: { message: _vm.current, transition: false },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-flex",
            { attrs: { "d-flex": "", "align-center": "" } },
            [
              _vm.mobile
                ? _c("ResizableText", {
                    staticClass: "h-full",
                    attrs: { message: _vm.statusText },
                  })
                : _c("MissionStatus", { attrs: { hideInstant: "" } }, [
                    _vm._v(" " + _vm._s(_vm.statusText) + " "),
                  ]),
            ],
            1
          ),
      _vm.audioUrl
        ? _c("SyncedMedia", {
            ref: "audio",
            attrs: { src: _vm.audioUrl },
            model: {
              value: _vm.state,
              callback: function ($$v) {
                _vm.state = $$v
              },
              expression: "state",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }