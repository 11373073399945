<template>
  <v-layout column fill-height class="lipdub-mission">
    <template v-if="!isAutopilotOn">
      <template v-if="isHost && !!audioUrl && !recording">
        <v-btn
          v-if="paused"
          class="play-stop-btn"
          icon
          color="success"
          @click="play()"
        >
          <v-icon>play_arrow</v-icon>
        </v-btn>
        <v-btn
          v-else
          color="success"
          class="play-stop-btn"
          icon
          @click="stop()"
        >
          <v-icon>stop</v-icon>
        </v-btn>
      </template>
      <template v-if="isSuper && !!audioUrl">
        <v-btn
          v-if="!recording && isEncoded"
          class="replay-stop-btn"
          icon
          color="purple"
          @click="reset()"
        >
          <v-icon>replay</v-icon>
        </v-btn>

        <v-btn
          v-if="!recording"
          class="record-stop-btn"
          icon
          color="red"
          @click="record()"
        >
          <v-icon>play_arrow</v-icon>
        </v-btn>

        <v-btn
          v-else
          color="red"
          class="record-stop-btn"
          icon
          @click="stopRecording()"
        >
          <v-icon>stop</v-icon>
        </v-btn>
      </template>
    </template>

    <v-flex d-flex class="mission-instructions" ref="root" v-if="showLyrics">
      <transition name="mission-instructions-transition">
        <ResizableText
          :key="`lipdub-instructions-chunk-${index}`"
          :class="`mission-instructions-chunk chunk-${index}`"
          :message="current"
          :transition="false"
        />
      </transition>
    </v-flex>

    <v-flex v-else d-flex align-center>
      <ResizableText v-if="mobile" :message="statusText" class="h-full" />
      <MissionStatus v-else hideInstant>
        {{ statusText }}
      </MissionStatus>
    </v-flex>

    <SyncedMedia v-if="audioUrl" ref="audio" v-model="state" :src="audioUrl" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { TweenMax, Back } from "gsap/TweenMax"
import { db } from "@/firebase"
import * as moment from "moment"
import { KEY_SPACE } from "keycode-js"

import User from "@shared/User"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText"
import SyncedMedia from "@/modules/smart-media/components/SyncedMedia"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus.vue"

const TIMESTAMP_REGEXP = /\[(.*?)\]/g

const wrapWithSpan = word => {
  const brRegexp = /<br\s*\/?>/
  const hasBR = brRegexp.test(word)
  const cleanedWord = word.replace(brRegexp, "").trim()

  const wrappedWord = cleanedWord.length
    ? `<span style="display:inline-block;white-space:pre;">${cleanedWord} </span>`
    : ``

  return wrappedWord + (hasBR ? "<br>" : "")
}

const splitIntoWords = string => string.match(/\S+\s*/g) || []

const serialize = string => String(string).trim()

export default {
  name: "Lipdub",
  components: {
    ResizableText,
    SyncedMedia,
    MissionStatus
  },
  props: {
    mission: Object,
    mode: String,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      audio: null,
      ref: null,
      index: 0,
      recording: false,
      tempInstructions: null,
      spaceBarCount: 0
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "gameID",
      "orgID",
      "game",
      "isAutopilotOn",
      "gameStatus",
      "isScribe"
    ]),
    ...mapGetters("auth", ["isHost", "isSuper"]),
    ...mapGetters("group", ["media"]),
    paused() {
      return !this.isAutopilotOn && (this.media?.paused ?? true)
    },
    startedAt() {
      return this.isAutopilotOn ? this.startTime : this.media?.startedAt
    },
    state: {
      get() {
        return {
          paused: this.paused,
          currentTime: this.media?.currentTime,
          startedAt: this.startedAt
        }
      },
      set(value) {
        if (!this.isHost) return
        this.$store.dispatch("group/updateMissionMediaState", {
          paused: value.paused,
          currentTime: value.currentTime,
          startedAt: value.startedAt
        })
      }
    },
    instructions() {
      return String(this.mission?.instructions).split(";").map(serialize)
    },
    instructionsHtml() {
      return this.instructions.map(string =>
        splitIntoWords(string).map(wrapWithSpan).join("")
      )
    },
    isEncoded() {
      const regex = new RegExp(TIMESTAMP_REGEXP)
      return regex.test(this.mission.instructions)
    },
    snippet() {
      return this.instructions[this.index]
    },
    current() {
      return this.instructionsHtml[this.index].replace(TIMESTAMP_REGEXP, "")
    },
    audioUrl() {
      return this.mission?.audio
    },
    missionID() {
      return this.mission?.id
    },
    startTime() {
      return this.gameStatus.missionStarted
    },
    showLyrics() {
      if (!this.mobile || User.isPresenter(this.user) || this.isScribe)
        return true
      return false
    },
    statusText() {
      return !this.isScribe ? "Waiting for your turn..." : "It's your turn!"
    }
  },
  async created() {
    if (this.isSuper) window.addEventListener("keyup", this.onKeyUp)

    this.ref = db
      .auxiliary()
      .ref(
        `org/${this.orgID}/game/${this.gameID}/lipdub/${this.missionID}/index`
      )

    this.ref.on("value", this.updateIndex)

    if (this.$store.getters["auth/volume"] < 0.5)
      this.updateUserVolume({ userID: this.user?.id, volume: 0.5 })
  },

  beforeDestroy() {
    if (this.isSuper) window.removeEventListener("keyup", this.onKeyUp)
    this.ref?.off("value", this.updateIndex)
  },
  watch: {
    index(value) {
      this.$nextTick(() => {
        const elements = document.querySelectorAll(`.chunk-${value} span`)
        TweenMax.staggerFrom(
          elements,
          0.3,
          {
            delay: 0.45,
            opacity: 0,
            y: 30,
            ease: Back.easeOut.config(2)
          },
          0.1
        )
      })
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateUserVolume"]),
    onKeyUp(e) {
      if (
        this.recording &&
        e.keyCode === KEY_SPACE &&
        this.tempInstructions.length !== this.spaceBarCount
      ) {
        const currentTime = this.$refs.audio.$el.currentTime
        const timeMarker = moment.utc(currentTime * 1000).format("mm:ss")

        const index =
          this.spaceBarCount < this.index ? this.index - 1 : this.index

        ++this.spaceBarCount

        this.tempInstructions[index] += `[${timeMarker}]`

        this.$store.dispatch("updateMission", {
          instructions: this.tempInstructions.join(";\n"),
          theKey: this.mission.id
        })
      }
    },
    updateIndex(snapshot) {
      this.index = parseInt(snapshot.val()) || 0
    },
    async reset() {
      try {
        await this.$confirm({
          message: "Reset all time encoding",
          buttonColor: "danger"
        })
        this.tempInstructions = this.mission.instructions.replace(
          TIMESTAMP_REGEXP,
          ""
        )
        this.$store.dispatch("updateMission", {
          instructions: this.tempInstructions,
          theKey: this.mission.id
        })
      } catch (e) {}
    },
    async record() {
      if (this.index !== 0) {
        await this.$info("Reset all time encoding")
        return
      }

      try {
        if (this.isEncoded) {
          await this.$confirm({
            message: "All time encoding will be overwritten",
            buttonColor: "danger"
          })
        }
        this.spaceBarCount = 0
        this.tempInstructions = this.mission.instructions
          .replace(TIMESTAMP_REGEXP, "")
          .split(";")
          .map(serialize)
        this.play()
        this.recording = true
      } catch (error) {}
    },
    stopRecording() {
      this.stop()
      this.recording = false
    },
    play() {
      this.state = {
        paused: false,
        currentTime: 0,
        startedAt: Date.now()
      }
    },
    stop() {
      this.state = {
        paused: true,
        currentTime: 0,
        startedAt: Date.now()
      }
    }
  }
}
</script>

<style lang="scss">
.lipdub-mission {
  .mission-instructions-transition-enter-active,
  .mission-instructions-transition-leave-active {
    transition: all ease 0.6s;
    transform: translateY(0);
    opacity: 1;
  }
  .mission-instructions-transition-enter-active {
    transition-delay: 0.3s;
  }
  .mission-instructions-transition-enter {
    transform: translateY(80px);
  }
  .mission-instructions-transition-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }
  .mission-instructions-chunk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .mission-instructions {
    position: relative;

    margin-bottom: 10px;
  }
  .play-stop-btn {
    position: absolute;
    z-index: 99;
    margin: 0;
  }
  .audio-element {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .record-stop-btn {
    position: absolute;
    right: 10px;
    z-index: 99;
    margin: 0;
  }
  .replay-stop-btn {
    position: absolute;
    right: 50px;
    z-index: 99;
    margin: 0;
  }
}
</style>
